import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

const App = () => {
  const [language, setLanguage] = useState('es');
  const [area, setArea] = useState(0);
  const [unit, setUnit] = useState('m2');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const switchLanguage = () => {
    setLanguage(language === 'en' ? 'es' : 'en');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  const calculatePrice = () => {
    const conversionRate = unit === 'ft2' ? 0.092903 : 1;
    const areaInMeters = area * conversionRate;
    return areaInMeters * 20;
  };

  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={language === 'en' ? "Waterproof DOAP: Protect your roof with our durable, adhesive-backed waterproof membrane. Ideal for all types of roofs, including Nicalit and 'sin'. Lasts up to 10 years." : "Waterproof DOAP: Proteja su techo con nuestra membrana impermeable duradera y autoadhesiva. Ideal para todo tipo de techos, incluyendo Nicalit y 'sin'. Dura hasta 10 años."} />
        <meta name="keywords" content={language === 'en' ? "Waterproof, Roof Repair, Adhesive Membrane, Nicalit, Roof Protection, Durable, Cost-effective" : "Impermeable, Reparación de Techo, Membrana Adhesiva, Nicalit, Protección de Techo, Duradero, Rentable"} />
        <meta name="author" content="Waterproof DOAP" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>{language === 'en' ? "Waterproof DOAP - Protect Your Roof" : "Waterproof DOAP - Proteja su Techo"}</title>

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={language === 'en' ? "Waterproof DOAP - Protect Your Roof" : "Waterproof DOAP - Proteja su Techo"} />
        <meta property="og:description" content={language === 'en' ? "Waterproof DOAP: Protect your roof with our durable, adhesive-backed waterproof membrane. Ideal for all types of roofs, including Nicalit and 'sin'. Lasts up to 10 years." : "Waterproof DOAP: Proteja su techo con nuestra membrana impermeable duradera y autoadhesiva. Ideal para todo tipo de techos, incluyendo Nicalit y 'sin'. Dura hasta 10 años."} />
        <meta property="og:image" content="%PUBLIC_URL%/images/waterproof-doap-social-image.jpg" />
        <meta property="og:url" content="https://waterproof.doap.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={language === 'en' ? "Waterproof DOAP - Protect Your Roof" : "Waterproof DOAP - Proteja su Techo"} />
        <meta name="twitter:description" content={language === 'en' ? "Waterproof DOAP: Protect your roof with our durable, adhesive-backed waterproof membrane. Ideal for all types of roofs, including Nicalit and 'sin'. Lasts up to 10 years." : "Waterproof DOAP: Proteja su techo con nuestra membrana impermeable duradera y autoadhesiva. Ideal para todo tipo de techos, incluyendo Nicalit y 'sin'. Dura hasta 10 años."} />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/waterproof-doap-social-image.jpg" />
        <meta name="twitter:site" content="@YourTwitterHandle" /> {/* Optional */}
      </Helmet>

      <header>
        <img src="/waterproof-doap-logo.png" alt="Waterproof DOAP Logo" className="logo" />
        <h1>{language === 'en' ? 'Waterproof Durable Opaque Adhesive Plastic!' : 'Waterproof Durable Opaque Adhesivo Plastico'}</h1>
        <h2>{language === 'en' ? 'Protect Your Roof with Waterproof DOAP!' : '¡Proteja su Techo con Waterproof DOAP!'}</h2>
        <button onClick={switchLanguage}>
          {language === 'en' ? 'Switch to Spanish' : 'Cambiar a Inglés'}
        </button>
      </header>

      <section className="description">
        <h2>{language === 'en' ? 'A cost-effective, long-lasting solution for all types of roofs' : 'Una solución rentable y duradera para todo tipo de techos'}</h2>
        <p>
          {language === 'en'
            ? 'Are you tired of roof leaks and expensive repairs? Introducing Waterproof DOAP (Waterproof Durable Opaque Adhesive Plastic), the ultimate waterproof membrane that provides durable and opaque protection for your roof. Our adhesive-backed membrane is quick and easy to install, saving you time and money.'
            : '¿Está cansado de goteras y reparaciones costosas en su techo? Presentamos Waterproof DOAP (Plástico Adhesivo Óptimo Duradero e Impermeable), la membrana impermeable definitiva que proporciona protección duradera y opaca para su techo. Nuestra membrana adhesiva es rápida y fácil de instalar, ahorrándole tiempo y dinero.'
          }
        </p>
      </section>

      <section className="videos-section">
        <h3>{language === 'en' ? 'Videos' : 'Vídeos'}</h3>
        <div className="videos">
          <video controls>
            <source src="/videos/waterproof-doap-roofing-plastic1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video controls>
            <source src="/videos/waterproof-doap-roofing-plastic2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video controls>
            <source src="/videos/waterproof-doap-roofing-plastic3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <section className="benefits">
        <h3>{language === 'en' ? 'Key Benefits' : 'Beneficios Clave'}</h3>
        <ul>
          <li>{language === 'en' ? 'Waterproof: Keeps your roof dry and leak-free.' : 'Impermeable: Mantiene su techo seco y sin filtraciones.'}</li>
          <li>{language === 'en' ? 'Durable: Lasts up to 10 years with minimal maintenance.' : 'Duradero: Dura hasta 10 años con un mantenimiento mínimo.'}</li>
          <li>{language === 'en' ? 'Opaque Adhesive: Sticks to all types of roofs, including Nicalit and \'sin\'.' : 'Adhesivo Opaco: Se adhiere a todo tipo de techos, incluyendo Nicalit y \'sin\'.'}</li>
          <li>{language === 'en' ? 'Affordable: Only $20 per meter, including cleaning, prep, and installation.' : 'Económico: Solo $20 por metro, incluyendo limpieza, preparación e instalación.'}</li>
        </ul>
        <img src="/waterproof-doap-roof-repair1.webp" alt="Roof Repair" className="square-image" />
      </section>

      <section className="packages">
        <h3>{language === 'en' ? 'Packages Available' : 'Paquetes Disponibles'}</h3>
        <ul>
          <li>{language === 'en' ? 'Small Roof (50 m²): $1,000' : 'Techo Pequeño (50 m²): $1,000'}</li>
          <li>{language === 'en' ? 'Medium Roof (100 m²): $2,000' : 'Techo Mediano (100 m²): $2,000'}</li>
          <li>{language === 'en' ? 'Large Roof (150 m²): $3,000' : 'Techo Grande (150 m²): $3,000'}</li>
        </ul>
        <img src="/waterproof-doap-roof-repair2.jpg" alt="Roof Repair" className="square-image" />
      </section>

      <section className="service-area">
        <h3>{language === 'en' ? 'Service Area' : 'Área de Servicio'}</h3>
        <p>{language === 'en' ? 'We proudly serve the area within the triangle formed by Managua, El Transito, and San Juan del Sur, and everything in between.' : 'Servimos con orgullo el área dentro del triángulo formado por Managua, El Transito y San Juan del Sur, y todo lo que está en medio.'}</p>
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1aXfLhsv60cJFGc4kjHEHQhWTYT8KEmB3" width="640" height="480"></iframe>
      </section>

      <section className="contact-form">
        <h3>{language === 'en' ? 'Contact Us for a Free Quote' : 'Contáctenos para una Cotización Gratuita'}</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>{language === 'en' ? 'Name:' : 'Nombre:'}</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div>
            <label>{language === 'en' ? 'Email:' : 'Correo Electrónico:'}</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <label>{language === 'en' ? 'Message:' : 'Mensaje:'}</label>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} required />
          </div>
          <button type="submit">{language === 'en' ? 'Submit' : 'Enviar'}</button>
        </form>
      </section>

      <section className="price-calculator">
        <h3>{language === 'en' ? 'Price Calculator' : 'Calculadora de Precios'}</h3>
        <div>
          <label>{language === 'en' ? 'Area:' : 'Área:'}</label>
          <input type="number" value={area} onChange={(e) => setArea(e.target.value)} required />
          <select value={unit} onChange={(e) => setUnit(e.target.value)}>
            <option value="m2">{language === 'en' ? 'Square Meters' : 'Metros Cuadrados'}</option>
            <option value="ft2">{language === 'en' ? 'Square Feet' : 'Pies Cuadrados'}</option>
          </select>
        </div>
        <p>{language === 'en' ? 'Estimated Price:' : 'Precio Estimado:'} ${calculatePrice().toFixed(2)}</p>
      </section>

      <footer>
        <h3>{language === 'en' ? 'Contact Us' : 'Contáctenos'}</h3>
        <p>{language === 'en' ? 'Contact us today for a free quote and discover how Waterproof DOAP can protect your roof!' : '¡Contáctenos hoy para una cotización gratuita y descubra cómo Waterproof DOAP puede proteger su techo!'}</p>
        <p>{language === 'en' ? 'Website: ' : 'Sitio Web: '} <a href="https://waterproof.doap.com">https://waterproof.doap.com</a></p>
        <p>{language === 'en' ? 'Phone: ' : 'Teléfono: '} +505-5751-9308</p>
        <p>{language === 'en' ? 'Email: ' : 'Correo Electrónico: '} <a href="mailto:info@doap.com">info@doap.com</a></p>
      </footer>
    </div>
  );
};

export default App;

